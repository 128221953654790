import Tree, {RawNodeDatum} from "react-d3-tree";
import {StoryNodeWC} from "../types/Book";
import React from "react";
import './BookTree.css'
import {Helmet} from "react-helmet";
import {DAGCBOR} from "@helia/dag-cbor";

export const BookTree = (props: {
    book: StoryNodeWC,
    heliaDagcbor: DAGCBOR
}) => {
    const book = props.book;
    const [graph, setGraph] = React.useState<RawNodeDatum>();
    const convert = async (node: StoryNodeWC) => {
        const children: StoryNodeWC[] = await node.subNodesWC(props.heliaDagcbor);
        const raw: RawNodeDatum[] = await Promise.all(children.map(convert));
        const datum: RawNodeDatum =  {
            name: node.node.title,
            attributes: {id: node.cid.toString()},
            children: raw
        };
        return datum;
    };
    React.useEffect(() => {
        book && convert(book).then(setGraph)
    }, [book])

    return <>
        <Helmet>
            <title>{`BD: ${book?.node.title} (Tree)`}</title>
        </Helmet>
        {
            graph ? <div className={"TreeDiv"}><Tree data={graph} /></div> : <>Loading...</>
        }</>
}