import React from "react";
import axios from "axios";
import {Button, ButtonGroup, ListGroup, ListGroupItem, Spinner} from "react-bootstrap";
import {useCookies} from "react-cookie";
import {Helmet} from "react-helmet";
import {useNavigate} from "react-router-dom";
import {CID} from "multiformats";
import {DAGCBOR} from '@helia/dag-cbor'
import {snfetcher, StoryNodeWC} from "../types/Book";
import {UUID} from "node:crypto";

export const BookMenu = (props: {
    helia: DAGCBOR,
    books: UUID[],
    booksReload: () => void
}) => {
    const books = props.books;
    const toggleReload = props.booksReload;

    const newBook = () => {
        const title = prompt("Title: ");
        console.log(title);
        if (title)
            axios.post('/books',
                title,
                {
                    headers: {
                        "Content-Type": "application/octet-stream"
                    }
                }).catch(err => {
                alert("Sorry, couldn't add the book.");
                console.error(err)
            }).then(toggleReload);
    }

    const authCookieName = "dagon-auth-cookie";
    const [cookies, , deleteCookie] = useCookies([authCookieName]);
    const merge = () => {
        const pairId = window.prompt(`Your pairing key is '${cookies[authCookieName]}'.
        Be careful as to not share it because it gives the holder the right
        to control your account.
        If you however are standing at a session you wish to control,
        you may enter your pairing key from another session and these
        two will get merged together.`)
        if (pairId) {
            axios.post(`/users/become`, pairId, {
                headers: {
                    "Content-Type": "application/octet-stream"
                }
            }).then(toggleReload);
        }
    }

    const logout = () => {
        deleteCookie(authCookieName)
        toggleReload()
    }

    const BookButton = (lprops: { bookUuid: UUID }) => {
        const bookUuid = lprops.bookUuid;
        const [book, setBook] = React.useState<StoryNodeWC>();
        React.useEffect(() => {
            axios.get<string>(`/books/${bookUuid}`).then((res) =>
                snfetcher(props.helia)(CID.parse(res.data)).then(setBook)
            );
        }, [bookUuid]);
        return <ListGroupItem title={book?.node.title}
                              key={book?.cid.toString()}>
            <Button
                disabled={!book}
                href={`/book/${lprops.bookUuid}`}>
                {book?.node.title ?? <Spinner/>}
            </Button>
        </ListGroupItem>
    }

    const navigate = useNavigate();
    return <>
        <Helmet>
            <title>BD Book overview</title>
        </Helmet>
        <h1>Book Menu</h1>
        <ListGroup>{books.map((uuid) => <BookButton bookUuid={uuid}/>)}
        </ListGroup>
        <hr/>
        <ButtonGroup>
            <Button onClick={newBook}>New Book</Button>
            <Button onClick={merge}>Merge library</Button>
            <Button onClick={logout}>Logout</Button>
        </ButtonGroup>
    </>
}