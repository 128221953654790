import React from "react";
import {Alert, Button, ButtonGroup, DropdownButton, DropdownItem, Spinner, Table} from "react-bootstrap";
import {Link, useNavigate} from "react-router-dom";
import axios from "axios";
import {Book, snfetcher, StoryNode, StoryNodeWC} from "../types/Book";
import ReactTimeAgo from "react-time-ago";
import {BookFooter} from "./BookFooter";
import {Helmet} from "react-helmet";
import {CID} from "multiformats";
import {DAGCBOR} from "@helia/dag-cbor";


export const BookEditor = (props: {
    book: Book,
    heliaDagcbor: DAGCBOR,
    toggleReload: () => void,
    globalReload: () => void,
}) => {
    const book = props.book;
    const navigate = useNavigate();
    const toggleReload = props.toggleReload;
    const globalReload = props.globalReload;
    const [loadingNew, setLoadingNew] = React.useState(false);

    const deleteBook = () => {
        if (window.confirm("Are you sure?")) {
            axios.delete(`/books/${book.uuid}`).then(() => {
                navigate("/")
                globalReload()
            })
        }
    }

    const deleteStory = (node: CID) => {
        if (window.confirm("Are you sure?")) {
            book && axios.delete(`/books/${book.uuid}/stories/${node}`).then(toggleReload)
        }
    }

    const insertStoryNode = () => {
        const url = prompt("URL:");
        if (url !== null) {
            setLoadingNew(true);
            axios.post<number>(`/stories`, url, {
                headers: {
                    "Content-Type": "application/octet-stream",
                }
            }).then(
                (res: { data: number }) => {
                    const storyId = res.data;
                    axios.post(`/books/${book.uuid}/stories`, storyId, {
                        headers: {
                            "Content-Type": "application/json",
                        }
                    }).then(
                        () => {
                            toggleReload()
                            setLoadingNew(false);
                            alert("Story added successfully.");
                        }
                    ).catch(() => {
                        alert("Couldn't add your story to the book");
                        setLoadingNew(false);
                    });
                }
            ).catch(() => {
                alert("Couldn't intern story.");
                setLoadingNew(false);
            });
        }
    };

    const StoryUpdated = (props: { node: StoryNodeWC, index: number }) => {
        const node = props.node;
        const [date, setDate] = React.useState();
        const src = node.node.src;
        React.useEffect(() => {
            axios.get(`/stories/${node.cid}/lastUpdated`).then((res) =>
                setDate(res.data));
        }, [node]);

        const refresh = () => {
            setLoadingNew(true);
            axios.put<string>(`/stories`, src,
                {headers: {"Content-Type": "application/octet-stream"}}).then(
                (res) => {
                    const newId = res.data;
                    if (CID.parse(newId) !== node.cid) {
                        axios.post(`/books/${book.uuid}/stories`, newId).then(() => {
                            axios.post(`/books/${book.uuid}/stories/${newId}/move`, {
                                to: props.index
                            }, {}).then(() => {
                                axios.delete(`/books/${book.uuid}/stories/${node.cid}`).then(() => {
                                    toggleReload();
                                    setLoadingNew(false);
                                });
                            });
                        });
                    } else {
                        setLoadingNew(false);
                        toggleReload();
                    }
                }
            )
        }

        return <DropdownButton
            title={date ? <ReactTimeAgo date={date}/> : <><Spinner size="sm"/>&nbsp;Loading...</>}>
            <DropdownItem>{src && <Link to={src}>Source</Link>}</DropdownItem>
            <DropdownItem>
                <Button disabled={!src} onClick={refresh}>
                    Refresh
                </Button></DropdownItem>
        </DropdownButton>;
    }

    const StoryMenu = (lprops: { nodeCid: CID, index: number }) => {
        const [node, setNode] = React.useState<StoryNodeWC>();
        React.useEffect(() => {
                snfetcher(props.heliaDagcbor)(lprops.nodeCid).then(setNode)
            },
            [lprops.nodeCid]);

        const move = (nodeId: CID, shift: number) => {
            axios.post(`/books/${book.uuid}/stories/${nodeId}/move`, {
                by: shift
            })
                .then(toggleReload);
        };

        return <>{node && <tr title="">
            <td>{node.node.title}</td>
            <td>{node.cid.toString()}</td>
            <td><StoryUpdated node={node} index={lprops.index}/></td>
            <td>
                <ButtonGroup>
                    <Button onClick={() => node && move(node.cid, -1)}>⮝</Button>
                    <Button onClick={() => node && move(node.cid, 1)}>⮟</Button>
                </ButtonGroup>
            </td>
            <td><Button variant="danger" onClick={() => node && deleteStory(node.cid)}>Delete</Button></td>
        </tr>}</>
    }

    const rename = () => {
        const newTitle = prompt("New Title:");
        globalReload()
        if (newTitle !== null)
            axios.put(`/books/${book.uuid}`,
                newTitle
                , {
                    headers: {
                        "Content-Type": "application/octet-stream",
                    }
                }).then(toggleReload);
    }

    const insertBook = () => {
        const subId = prompt("ID:");
        if (subId) {
            setLoadingNew(true);
            axios.post(`/books/${book.uuid}/stories`, subId).then(() => {
                    toggleReload();
                    setLoadingNew(false);
                }
            ).catch(() => alert("Couldn't add your book, possibly would become subnode of itself?"));
        }
    }

    return <>
        <Helmet>
            <title>{`BD: ${book.node.node.title} (Editing)`}</title>
        </Helmet>
        <h1 className={"display-1"}>Editing: {book.node.node.title}</h1>
        {loadingNew ? <Alert>
            <Spinner size="sm"/>&nbsp;
            Loading new...
        </Alert> : <></>}
        <Table striped bordered hover>
            <thead>
            <tr>
                <th>Title</th>
                <th>ID</th>
                <th>Last Update</th>
                <th>Reorder</th>
                <th>Delete</th>
            </tr>
            </thead>
            <tbody>
            {book.node.node.subNodes?.map(((x, idx) => <StoryMenu nodeCid={x} index={idx}/>))}
            </tbody>
        </Table>
        <ButtonGroup>
            <DropdownButton as={ButtonGroup} title="+">
                <DropdownItem onClick={insertStoryNode}>From URL</DropdownItem>
                <DropdownItem onClick={insertBook}>By ID</DropdownItem>
            </DropdownButton>
            <Button onClick={rename}>Rename</Button>
            <Button onClick={deleteBook} variant="danger">Delete Book</Button>
        </ButtonGroup>
    </>
}