import {useNavigate} from "react-router-dom";
import React from "react";
import {StoryNode, StoryNodeWC} from "../types/Book";
import Accordion from 'react-bootstrap/Accordion';
import {Button, ButtonGroup} from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import {Helmet} from 'react-helmet';
import {CID} from "multiformats";
import {DAGCBOR} from "@helia/dag-cbor";
import {UnixFS} from "@helia/unixfs";
import all from "it-all";
import IframeResizer from '@iframe-resizer/react';

const iframeResizerChildScript = "<script\n" +
    "  src=\"https://cdn.jsdelivr.net/npm/@iframe-resizer/child@5.3.2\"\n" +
    "  type=\"text/javascript\"\n" +
    "  async\n" +
    "></script>";

const darkThemeScript = "" +
    "<script src=\"https://code.jquery.com/jquery.js\"></script>" +
    "<script>" +
    "  const updateTheme = () => {\n" +
    "        const colorMode = window.matchMedia(\"(prefers-color-scheme: dark)\").matches ? \"dark\" : \"light\";\n" +
    "        document.querySelector(\"html\")?.setAttribute(\"data-bs-theme\", colorMode);\n" +
    "    };" +
    "        updateTheme();\n" +
    "        window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', updateTheme)\n" +
    " </script>" +
    "<link href=\"https://cdn.jsdelivr.net/npm/bootstrap@5.3.3/dist/css/bootstrap.min.css\"" +
    " rel=\"stylesheet\"" +
    " integrity=\"sha384-QWTKZyjpPEjISv5WaRU9OFeRpok6YctnYmDr5pNlyT2bRjXh0JMhjY6hW+ALEwIH\" crossorigin=\"anonymous\">" +
    "<script src=\"https://cdn.jsdelivr.net/npm/bootstrap@5.3.3/dist/js/bootstrap.bundle.min.js\" " +
    "integrity=\"sha384-YvpcrYf0tY3lHB60NNkmXc5s9fDVZLESaAA55NDzOxhy9GkcIdslK1eN7N6jIeHz\" " +
    "crossorigin=\"anonymous\">" +
    "</script>";

export const BookView = (props: {
    heliaUnixfs: UnixFS,
    heliaDagcbor: DAGCBOR,
    node: StoryNodeWC,
    toggleReload: () => void
}) => {
    const node = props.node;
    const navigate = useNavigate();

    const NodeText = (lprops: { node: StoryNode }) => {
        const [text, setText] = React.useState<string>("");
        React.useEffect(() => {
            lprops.node.text && all(props.heliaUnixfs.cat(lprops.node.text)).then(
                (data) =>
                    new Blob([
                        iframeResizerChildScript,
                        ...data,
                        darkThemeScript,
                    ]).text().then(setText)
                )
        }, [lprops.node.text])
        return <>{text && <IframeResizer
            title={lprops.node.title}
            srcDoc={text}
            style={{height: "100vh", width: "100%"}}
            license={"GPLv3"}
        />}</>;
    }

    const RenderStoryNode = (lprops: { nodeCid: CID }) => {
        const nodeCid = lprops.nodeCid;
        const [node, setNode] = React.useState<StoryNode>();
        React.useEffect(() => {
            props.heliaDagcbor.get<StoryNode>(nodeCid).then(setNode)
        }, [nodeCid])
        return <>{node && <Accordion.Item eventKey={node.title}>
            <Accordion.Header>{node.title}</Accordion.Header>
            <Accordion.Body><>
                <NodeText node={node}/>
                <Accordion alwaysOpen>
                    {node.subNodes?.map((node) =>
                        <RenderStoryNode nodeCid={node}/>)}
                </Accordion>
            </>
            </Accordion.Body>
        </Accordion.Item>}</>;
    };


    return <>
        <Helmet>
            <title>{`BD: ${node.node.title}`}</title>
        </Helmet>
        <h1 className={"display-1"}>{node.node.title}</h1>
        <hr/>
        <Accordion alwaysOpen>
            {node.node?.subNodes?.map(node =>
                <RenderStoryNode nodeCid={node}/>
            )}
        </Accordion>
        <hr/>
        <ButtonGroup>
            <Button onClick={() => navigate('./edit')}>Edit</Button>
            <Button onClick={() => navigate('./view')}>View Tree</Button>
        </ButtonGroup>
    </>
}
