import {ButtonGroup, DropdownButton, DropdownItem} from "react-bootstrap";
import React from "react";
import {Book} from "../types/Book";
import axios from "axios";
import {CID} from "multiformats";
import {useNavigate} from "react-router-dom";
import {Helia} from "helia";
import {UnixFS} from "@helia/unixfs";
import all from 'it-all'

export const BookFooter = (props: { book: Book, unixfs: UnixFS }) => {
    const navigate = useNavigate();
    const downloadEpub = () => {
        axios.get<string>(`books/${props.book.uuid}/publish/epub`).then(async (res) => {
            const cid = CID.parse(res.data);
            const epubData = await all(props.unixfs.cat(cid));
            const blob = new Blob(epubData, { type: "application/epub" });
            let elm = document.createElement('a')
            elm.href = URL.createObjectURL(blob);
            elm.setAttribute('download', props.book.node.node.title + ".epub");
            elm.click()
        })
    }
    return <DropdownButton as={ButtonGroup} title="Export">
        {props.book.uuid &&
        <DropdownItem
            className={"dropdown-item"}
            target={"_blank"}
            href={`https://ipfs.michal-atlas.cz/ipfs/${props.book.node.cid}`}>IPFS</DropdownItem>}
        {props.book &&
        <DropdownItem
            className={"dropdown-item"}
            target={"_blank"}
            onClick={downloadEpub}>EPUB</DropdownItem>}
    </DropdownButton>
}
