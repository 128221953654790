import {Route, Routes, useParams} from "react-router-dom";
import {Book, snfetcher, StoryNode, StoryNodeWC} from "../types/Book";
import React from "react";
import axios from "axios";
import {BookEditor} from "./BookEditor";
import {BookTree} from "./BookTree";
import {BookView} from "./BookView";
import {Spinner} from "react-bootstrap";
import {DAGCBOR} from "@helia/dag-cbor";
import {CID} from "multiformats";
import {Helia} from "helia";
import {Strings} from "@helia/strings";
import {BookFooter} from "./BookFooter";
import {UUID} from "node:crypto";
import {UnixFS} from "@helia/unixfs";

export const BookRouter = (props: {
    heliaUnixfs: UnixFS,
    heliaStrings: Strings,
    mdagCbor: DAGCBOR,
    booksReload: () => void
}) => {
    const [book, setBook] = React.useState<Book>();
    const [cid, setCid] = React.useState<CID>();
    const {id} = useParams();
    const [reload, setReload] = React.useState(false);
    const toggleReload = () => {
        setReload(!reload);
    }

    React.useEffect(() => {
        id && axios.get<string>(`/books/${id}`).then((res) => {
            const lcid = CID.parse(res.data);
            setCid(lcid)
            snfetcher(props.mdagCbor)(lcid).then((node) => id && setBook(new Book(id as UUID, node)))
        })

    }, [id, props.mdagCbor, reload]);
    return book ? <><Routes>
        <Route path="/edit"
               element={<BookEditor toggleReload={toggleReload} book={book} globalReload={props.booksReload}
                                    heliaDagcbor={props.mdagCbor}/>}></Route>
        <Route path="/view"
               element={book && <BookTree book={book.node} heliaDagcbor={props.mdagCbor}/>}></Route>
        <Route path="/"
               element={<BookView toggleReload={toggleReload} heliaUnixfs={props.heliaUnixfs}
                                  heliaDagcbor={props.mdagCbor} node={book.node}/>}></Route>
    </Routes>{id && <BookFooter book={book} unixfs={props.heliaUnixfs}/>}</> : <><h1 className={"display-1"}><Spinner/> Loading
        ...</h1> </>
}