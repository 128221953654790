import {CID} from "multiformats";
import {DAGCBOR} from "@helia/dag-cbor";
import {UUID} from "node:crypto";

export const snfetcher = (dc: DAGCBOR) => (cid: CID) => {
    return dc.get<StoryNode>(cid).then((node) => new StoryNodeWC(cid, node))
}

export class Book {
    uuid!: UUID;
    node!: StoryNodeWC;

    constructor(uuid: UUID, node: StoryNodeWC) {
        this.uuid = uuid;
        this.node = node;
    }
}

export class StoryNodeWC {
    constructor(cid: CID, node: StoryNode) {
        this.cid = cid;
        this.node = node;
    }
    cid!: CID;
    node!: StoryNode;
    public subNodesWC(dc: DAGCBOR) {
        return Promise.all(this.node.subNodes?.map(snfetcher(dc)) ?? [])
    }
}

export class StoryNode {
    title!: string;
    subNodes?: Array<CID>;
    text?: CID;
    src?: string;
}